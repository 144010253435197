<template>
	<div class="help-other">
		<div class="item-info">
			<!-- <div class="item" v-for="(item, index) in articleList.list" :key="index" @click="detail(item)">
				<div class="item-title">{{ item.title }}</div>
				<div class="info">
					<div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
				</div>
			</div> -->
			<div class="detail-wrap">
				<!-- <el-breadcrumb separator="/" class="path">
					<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
						<i class="n el-icon-s-home"></i>
						首页
					</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/cms/about' }">关于我们</el-breadcrumb-item>
					<el-breadcrumb-item class="path-help">详情</el-breadcrumb-item>
				</el-breadcrumb> -->
				<div class="help-detail" v-loading="loading">
					<div class="title">{{ detail.title }}</div>
					<div class="info">
						<div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
					</div>
					<div class="content" v-html="detail.content"></div>
				</div>
			</div>
		</div>
	</div>
<!-- 	<div class="detail-wrap">
		<el-breadcrumb separator="/" class="path">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				<i class="n el-icon-s-home"></i>
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/cms/article' }">关于我们</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="help-detail" v-loading="loading">
			<div class="title">{{ detail.title }}</div>
			<div class="info">
				<div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
			</div>
			<div class="content" v-html="detail.content"></div>
		</div>
	</div> -->
</template>

<script>
	import {
		ArticlefriendlyLink
	} from "@/api/website";
	import {
		articleList,
		articleOther,
		articleDetail
	} from "@/api/cms/article";
	export default {
		name: "list_other",
		components: {},
		data: () => {
			return {
				articleList: [],
				id: 0,
				detail: [],
				linkList: [],
				loading: true,
				activeName: 'activeName'
			};
		},
		created() {
			this.id = this.$route.path.replace("/cms/about/listother-", "");
			this.getDetail();
		},
		watch: {
			$route(curr) {
				this.id = curr.params.pathMatch;
				this.getDetail();
			}
		},
		methods: {
			link() {
				ArticlefriendlyLink({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.linkList = res.data.list
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			getDetail() {
				articleDetail({
						id: this.id
					})
					.then(res => {
						if (res.code == 0) {
							if (res.data) {
								this.loading = false;
								this.detail = res.data;
							} else {
								this.$router.push({
									path: '/cms/about'
								});
							}
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
		}
	};
</script>
<style lang="scss" scoped>
	.help-detail {
		background-color: #ffffff;
		padding: 10px;
		border-radius: 5px;
		margin: 10px 0;

		.title {
			text-align: center;
			font-size: 18px;
			margin: 10px 0;
		}

		.info {
			margin: 0 43px;
			border-bottom: 1px dotted #e9e9e9;

			.time {
				text-align: center;
				color: #838383;
				margin-bottom: 17px;
			}
		}

		.content {
			padding-top: 10px;
			margin: 0 65px;
		}
	}

	.path {
		padding: 15px 0;
	}

	.friendly-link {
		width: $width;
		margin: 0 auto;
		border: 1px solid #e9e9e9;

		.link-title {
			line-height: 30px;
			padding: 10px 0 5px;
			margin: 0px 0 15px;
			border-bottom: 1px solid #e8e8e8;
		}

		.link-item {
			width: 10%;
			height: 50px;
			line-height: 47px;
			float: left;
			text-align: center;
			overflow: hidden;
			margin: 0 6px 10px 6px;
			-webkit-transition: opacity 0.3s, box-shadow 0.3s;
			transition: opacity 0.3s, box-shadow 0.3s;
			border: 1px solid #fff;
			border-left: none;
			cursor: pointer;
		}

		.link-item:hover {
			width: -webkit-calc(10% + 1px);
			width: calc(10% + 1px);
			margin-left: 4px;
			position: relative;
			opacity: 1;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			border: 1px solid #dddddd;
		}

		.clear {
			clear: both;
		}

		.el-tabs__active-bar is-top {
			width: 56px;
			transform: translateX(0px);
		}
	}
</style>
<style lang="scss">
	.friendly-link {
		.el-tabs__nav-scroll {
			padding-left: 30px;
			height: 50px;
			line-height: 50px;
		}

		.el-tabs__content {
			padding: 0 20px;
		}

		.el-tabs__nav-wrap::after {
			height: 1px;
		}

		.el-tabs__nav {
			padding: 0 20px;
		}

		.el-tabs__active-bar {
			padding: 0 20px;
		}

		.el-tabs__active-bar is-top {
			width: 56px;
			transform: translateX(0px);
		}
	}
</style>
<style lang="scss" scoped>
	.help-other {
		.item-info {
			padding: 10px;
			background-color: #ffffff;
			height: 500px;
			border: 1px solid #e9e9e9;

			.item {
				border-bottom: 1px #f1f1f1 solid;
				padding: 10px 0;
				display: flex;
				justify-content: space-between;

				&:last-child {
					border-bottom: none;
				}

				&:first-child {
					padding-top: 0px;
				}

				.item-title {
					font-size: $ns-font-size-base;
					color: #333333;
					display: inline-block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					overflow: hidden;
					cursor: pointer;

					&:hover {
						color: $base-color;
					}
				}
			}
		}
	}
</style>
